.radioContainer {
  border: 1px solid var(--color-border-light);
  margin: 0;
  border-radius: 6px;
  height: 72px;
  flex-basis: 72px;
  padding: 0 var(--space-1);
}

.radioGroup {
  gap: var(--space-2);
  flex-wrap: wrap;
}

.active {
  outline: 1px solid var(--color-primary-main);
  border-color: var(--color-primary-main);
}

.active .radioTitle {
  font-weight: bold;
}

.listItem {
  min-width: 0;
  margin-right: var(--space-1);
  color: var(--color-primary-main);
}

.active .radioSubtitle {
  color: var(--color-text-primary);
}

.statusButton {
  border-radius: 4px;
  padding: 6px;
  width: 32px;
  height: 32px;
  background: var(--color-warning-background);
  justify-self: flex-end;
  margin-left: auto;
}

.statusButton.processing {
  background: var(--color-info-background);
}

.newTxButton {
  justify-content: flex-start;
  text-align: left;
  padding: var(--space-2);
  border-radius: 6px;
  border: 1px solid var(--color-border-light);
  width: 100%;
  color: var(--color-text-primary);
  gap: var(--space-2);
}

.newTxButton:hover {
  border-color: var(--color-primary-main);
}

.iconBg {
  background: var(--color-background-main);
  display: inline-flex;
  border-radius: 50%;
  padding: var(--space-1);
}

@media (max-width: 400px) {
  .radioGroup {
    flex-direction: column;
  }
}
