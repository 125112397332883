.banner :global .MuiTooltip-tooltip {
  min-width: 384px !important;
  padding: var(--space-3);
}

.banner :global .MuiTooltip-tooltip,
.banner :global .MuiTooltip-arrow::before {
  border-color: var(--color-secondary-main);
}

[data-theme='dark'] .banner :global .MuiTooltip-tooltip,
[data-theme='dark'] .banner :global .MuiTooltip-arrow::before {
  border-color: var(--color-primary-main);
}

.container {
  min-width: 100%;
}

.close {
  position: absolute;
  top: var(--space-2);
  right: var(--space-2);
  color: var(--color-border-main);
}

.button {
  padding: 4px 10px;
}

.buttons {
  display: flex;
  gap: var(--space-2);
}

.chip {
  border-radius: 4px;
  background-color: var(--color-secondary-main);
  font-weight: 400;
  font-size: 12px;
  width: var(--space-5);
  height: 24px;
  position: relative;
}

[data-theme='dark'] .chip {
  color: var(--color-static-main);
  background-color: var(--color-primary-main);
}

.chip :global .MuiChip-label {
  padding: 0;
  text-overflow: unset;
}

.icon {
  margin-top: -12px;
  width: 64px;
  height: 64px;
}
